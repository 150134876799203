import React, {Component} from "react";
import $ from "jquery";
import {FormattedMessage} from "react-intl";

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.messageRef = React.createRef();
        this.sendForm = this.sendForm.bind(this);
        this.formMessage = "";
        this.state = {
            isSending: false
        };
    }

    render() {
        return (
            <div className="col-lg-12 contact-section p-5" id="contact">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-8">
                        <h3 className="important-title text-center my-3">
                            <FormattedMessage id="contact.title" defaultMessage="Contact me, or let me contact you :)"/>
                        </h3>
                    </div>
                    <div className="col-lg-8 my-3">
                        <p>info@tobama.com</p>
                        <label className="d-block">
                            <FormattedMessage id="contact.email" defaultMessage="Email"/>:
                            <input type="email" className="form-control" ref={this.emailRef} name="email"/></label>
                        <label className="d-block">
                            <FormattedMessage id="contact.anythingElse"
                                              defaultMessage="If there is anything else or you prefer other ways of communication, please let me know."/>
                            <textarea name="message" rows="5" className="form-control" ref={this.messageRef}></textarea>
                        </label>
                    </div>
                    <div className="col-lg-8">
                        <button className="primary-btn" onClick={this.sendForm} disabled={this.state.isSending}>
                            <FormattedMessage id="contact.connect"
                                              defaultMessage="Connect"/>
                        </button>
                    </div>
                    <div className="col-lg-8 my-3">
                        {this.state.formMessage}
                    </div>
                </div>
            </div>
        );
    }


    sendForm(e) {
        e.preventDefault();
        const email = this.emailRef.current.value;
        const message = this.messageRef.current.value;

        this.setState({
            isSending: true,
            formMessage: "Sending..."
        });

        $.ajax({
            url: '/sendmail.php',
            data: {email, message},
            method: 'post',
            dataType: 'json',
            success: this.handleFormSuccess,
            error: this.handleFormError,
            complete: this.handleFormComplete
        });
    }

    handleFormSuccess = (result) => {
        this.setState({
            formMessage: "Thank you, I'll check your message ASAP."
        });
        this.emailRef.current.value = "";
        this.messageRef.current.value = "";
    }

    handleFormError = (xhr, status, error) => {
        this.setState({
            formMessage: "An error occurred. Please try again later.",
            isSending: false
        });
        console.error(error);
    }

    handleFormComplete = () => {
        this.setState({
            isSending: false
        });
    }

}