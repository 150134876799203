import React, {Component} from "react";
import $ from "jquery";
import {FormattedMessage, FormattedNumber} from "react-intl";

export default class Duration extends Component {
    currentLang = navigator.language; //'fa';//
    prices = {
        en: {price: 50, currency: "EUR"},
        sv: {price: 500, currency: "SEK"},
        fa: {price: 321500, currency: "تومان"},
    };
    durations = {
        20: <FormattedMessage id="duration.twentyHours" defaultMessage="20 H"/>,
        40: <FormattedMessage id="duration.fortyHours" defaultMessage="40 H"/>,
        80: <FormattedMessage id="duration.eightyHours" defaultMessage="80 H"/>,
        160: (
            <FormattedMessage
                id="duration.hundredSixtyHours"
                defaultMessage="160 H"
            />
        ),
        idontknow: (
            <FormattedMessage
                id="duration.iDontKnow"
                defaultMessage="I don't know!"
            />
        ),
    };

    howLong = {
        "1month": <FormattedMessage id="duration.month" defaultMessage="1 month"/>,
        "3months": (
            <FormattedMessage id="duration.3months" defaultMessage="3 months"/>
        ),
        "6months": (
            <FormattedMessage id="duration.6months" defaultMessage="6 months"/>
        ),
        "12months": (
            <FormattedMessage id="duration.12months" defaultMessage="12 months"/>
        ),
        longtime: (
            <FormattedMessage id="duration.longtime" defaultMessage="Long Time"/>
        ),
    };
    componentDidMount() {
        this.setSelected("20")();
        this.selectRadio("1month")();
    }
    constructor(props) {
        super(props);
        this.state = {
            currency: this.prices[this.currentLang].currency,
            howMuch: 0,
            price: 0,
            howLong: 0,
        };
    }

    render() {
        return (
            <>
                <div className="col-lg-10 mt-3">
                    <h3 className="important-title my-3">
                        <FormattedMessage id="duration.title" defaultMessage="Time per a month?"/>
                    </h3>
                </div>
                <div className="col-5 my-3">
                    <h6><FormattedMessage id="duration.eachMonth" defaultMessage="Each Month"/></h6>
                    <div className="duration text-center">
                        {Object.keys(this.durations).map((item, index) => {
                            return (
                                <label
                                    onClick={this.setSelected(item)}
                                    htmlFor={item}
                                    className="custom-radio">
                                    <input type="radio" id={item} name="howMuch" value={item}/>
                                    {this.durations[item]}
                                </label>
                            );
                        })}
                    </div>
                </div>
                <div className="col-5 my-3">
                    <h6><FormattedMessage id="duration.howLong" defaultMessage="How long?"></FormattedMessage></h6>
                    <div className="duration how-long text-center">
                        {Object.keys(this.howLong).map((item, index) => {
                            return (
                                <label
                                    onClick={this.selectRadio(item)}
                                    htmlFor={item}
                                    className="custom-radio"
                                >
                                    <input type="radio" id={item} name="howLong" value={item}/>
                                    {this.howLong[item]}
                                </label>
                            );
                        })}
                    </div>
                </div>
                <div className="col-lg-10">
                    <div className="price text-center">
                        <span><FormattedMessage id="duration.price" defaultMessage="Price"></FormattedMessage>: </span>
                        <span className="price-value"></span>
                        <span className="price-value">
          {this.state.price}{" "}
                            {this.state.price !== "Call" ? this.state.currency : ""}
        </span>
                    </div>
                </div>
                <div className="col-10">
                    {Object.keys(this.durations).map((hours, index) => {
                        return (
                            <div className="duration-description" data-desc={`h` + hours}>
                                <h5>
                                    <FormattedMessage
                                        id="duration.whatIs"
                                        defaultMessage="What does it mean?"
                                    />
                                </h5>
                                <p>
                                    <FormattedMessage
                                        id={`duration.${hours}Hours.desc`}
                                        values={{hours: hours}}
                                    />
                                </p>
                                <h5>
                                    <FormattedMessage
                                        id="duration.whatWillYouGet"
                                        defaultMessage="What will you get?"
                                    />
                                </h5>
                                <p>
                                    <FormattedMessage
                                        id={`duration.${hours}Hours.whatWillYouGetDesc`}
                                        values={{hours: hours}}
                                    />
                                </p>
                                <h5>
                                    <FormattedMessage
                                        id="duration.whoWillBenefit"
                                        defaultMessage="Who will benefit?"
                                    />
                                </h5>
                                <p>
                                    <FormattedMessage
                                        id={`duration.${hours}Hours.whoWillBenefitDesc`}
                                    />
                                </p>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    selectRadio(value) {
        return (e) => {
            $(".how-long label").removeClass("selected");
            $('.how-long label input[value="' + value + '"]')
                .parent()
                .addClass("selected");
            this.setState({
                howLong: value,
            });
        };
    }

    setSelected(value) {
        return (e) => {
            $(".duration label").removeClass("selected");
            $('.duration label input[value="' + value + '"]')
                .parent()
                .addClass("selected");
            $(".duration-description[data-desc='h" + value + "']")
                .fadeIn()
                .siblings(".duration-description")
                .hide();

            if (value === "idontknow") {
                this.setState({
                    duration: value,
                    price: "Call",
                });
            } else {
                this.setState({
                    duration: value,
                    price: value.replace("m", "") * this.prices[this.currentLang].price,
                });
            }
        };
    }
}
