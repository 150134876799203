import App from './App';
// import TransitionGroup from 'react-addons-transition-group'

import registerServiceWorker from './registerServiceWorker';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

registerServiceWorker();
