import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

class SiteNavigation extends Component {
    render() {
        return (
            <ul className="list-unstyled m-0 d-flex justify-content-center">
                <li>
                    <a href="#services" className="link-text p-3">
                        <FormattedMessage id="navigation.whatService" defaultMessage="What service?"/>
                    </a>
                </li>
                <li>
                    <a href="#durations" className="link-text p-3">
                        <FormattedMessage id="navigation.duration" defaultMessage="Duration"/>
                    </a>
                </li>
                <li>
                    <a href="#contact" className="link-text p-3">
                        <FormattedMessage id="navigation.contact" defaultMessage="Contact"/>
                    </a>
                </li>
                <li>
                    <a href="#sampels" className="link-text p-3">
                        <FormattedMessage id="navigation.sampels" defaultMessage="Sampels"/>
                    </a>
                </li>
            </ul>
        );
    }
}

export default SiteNavigation;
