import React, { useEffect, useRef } from 'react';
import SiteNavigation from '../pages/SiteNavigation';
import {FormattedMessage} from "react-intl";

const Layout = (props) => {
    const logoRef = useRef(null);
    const navRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const logo = logoRef.current;
            const nav = navRef.current;

            // Calculate the distance from the top of the viewport to the navigation
            const navTop = nav.getBoundingClientRect().top;

            if (navTop <= 0) {
                // Add the class when the navigation reaches the top of the viewport
                logo.classList.add('scrolled');
            } else {
                // Remove the class when scrolling back up
                logo.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);   
    return (
        <>
            <div className="row m-0 vh-100 intro">
                <div className="intro-container col-12 mb-3 d-flex justify-content-center align-items-center">
                    <img ref={logoRef} src="/images/logo.svg" alt="Tobama" className="intro-logo"/>
                    <div className="text-center">
                        <h1 className="intro-title important-title">TOBAMA</h1>
                        <div className="separator"></div>
                        <h3 className="intro-description mt-3">
                            <FormattedMessage id="app.header"
                                              defaultMessage="Hire based on demand. We take it simple!"/>
                        </h3>
                    </div>
                </div>
            </div>
            <div className="row m-0 navigation" ref={navRef}>
                <div className="col-12 justify-content-middle"><SiteNavigation/></div>
            </div>
            {props.children}
        </>
    );
}

export default Layout;