import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.scss';
import './css/animate.scss';
import Layout from './pages/Layout';
import Intro from './pages/Intro';
import {Helmet} from "react-helmet";
import {
    HashRouter,
    Route,
    Routes
} from 'react-router-dom';
import Wrapper from "./componenets/Wrapper";


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            me: []
        }
    }

    
    render() {
        return (
            <Wrapper>
                <HashRouter>
                    <Layout projects={this.state.projects}>
                        <Helmet>
                            {this.state.projects.map((project, index) =>
                                <link rel="preload" as="image" href={project.thumb}/>
                            )}
                        </Helmet>
                        {<Routes>
                            {/* <Route path="/contact" Component={() => <Contact me={this.state.me}/>}/>
                            <Route exact path="/intro" Component={() => <Intro projects={this.state.projects}/>}/>
                             <Route path="/projects/:id" Component={(params) => <ProjectItem params={params}
                                                                                            projects={this.state.projects}/>}/>
                            <Route exact path="/projects" Component={() => <Projects projects={this.state.projects}/>}/>*/}
                            <Route path="*" Component={() => <Intro/>}/> 
                        </Routes>}
                    </Layout>
                </HashRouter>
            </Wrapper>
        )
    }
}

export default App;
