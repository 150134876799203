/**
 * Created by pedram on 5/2/2018.
 */
import React, { Component } from "react";
import MyNavLink from "../MyNavLink";
import $ from "jquery";
import Services from "./Services";
import Duration from "./Duration";
import Contact from "./Contact";
import { FormattedMessage } from "react-intl";
import Projects from "./Projects";

export default class Intro extends Component {
  componentDidMount() {
    let animationEnd = (function (el) {
      let animations = {
        animation: "animationend",
        OAnimation: "oAnimationEnd",
        MozAnimation: "mozAnimationEnd",
        WebkitAnimation: "webkitAnimationEnd",
      };

      for (let t in animations) {
        if (el.style[t] !== undefined) {
          return animations[t];
        }
      }
    })(document.createElement("div"));
    $(".hello-box").on(animationEnd, function () {
      $(".me").addClass("fadeIn");
    });
  }

  render() {
    return (
      <div className="row mx-0 justify-content-center content-place-holder align-items-center project-page">
        <div className="services durations col-12  p-5">
          <div className="row justify-content-center" id="services">
            <Services />
          </div>
          <div className="row justify-content-center" id="durations">
            <Duration />
          </div>
        </div>
        <Contact />

        <div className="sampels col-12">
          <div className="row justify-content-center" id="sampels">
            <Projects />
          </div>
        </div>
        <a class="primary-btn mx-auto mb-5" href="#contact">
          <FormattedMessage id="getConsultNow" defaultMessage="Get a consult now" />
        </a>
      </div>
    );
  }
}
