/**
 * Created by pedram on 5/2/2018.
 */
import React, { Component } from "react";
import { SquareArrowOutUpRight } from "lucide-react";
import $ from "jquery";
import TwoColumns from "./TwoColumns";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
    };
  }
  handleElements() {
    $(".project-item")
      .on("mouseover", function () {
        var splited = $(this).data("skills").split(",");
        for (var index2 = 0; index2 < splited.length; index2++) {
          if (splited[index2] !== "") {
            var v = "#" + splited[index2];
            $(v).addClass("active");
          }
        }
      })
      .on("mouseout", function () {
        var splited = $(this).data("skills").split(",");
        for (var index2 = 0; index2 < splited.length; index2++) {
          if (splited[index2] !== "") {
            var v = "#" + splited[index2];
            $(v).removeClass("active");
          }
        }
      });
  }
  componentDidUpdate() {
    this.handleElements();
  }
  componentDidMount() {
    this.handleElements();
    let reactThis = this;
    $.ajax({
      method: "get",
      cache: false,
      url: "./data/projects.json",
      success: function (data) {
        reactThis.setState({ projects: data });
      },
    });
  }

  render() {
    // const Icons = {
    //   SquareArrowOutUpRight: SquareArrowOutUpRight,
    // };
    return (
      <>
        <h3 className="text-center mt-5 important-title">
            <FormattedMessage id="projects.title" defaultMessage="If you are still scrolling here are some of our private projects:"/>
        </h3>
        <TwoColumns projects={this.state.projects}>
          <div className="view projects-list">
            <Scrollbars style={{ height: 400 }}>
              {this.state.projects.map((project, index) => (
                <div
                  key={index}
                  className="project-item selected col-6 mb-3 pb-3"
                  data-skills={
                    project.skills.frontend.map((i, k) =>
                      i.replace(/ /g, "-").replace(/&|\./g, "-")
                    ) +
                    "," +
                    project.skills.backend.map((i, k) =>
                      i.replace(/ /g, "-").replace(/&|\./g, "-")
                    )
                  }
                >
                  <a href={project.url} className="">
                    <div
                      className="img-holder"
                      style={{
                        backgroundImage: "url(" + project.thumb + ")",
                        paddingTop: "60%",
                        backgroundPosition: "center top",
                        backgroundSize: "100%",
                      }}
                    ></div>
                    <span className="project-name">
                        <SquareArrowOutUpRight size={18} className="d-inline"/> {project.name}
                    </span>
                  </a>
                </div>
              ))}
            </Scrollbars>
          </div>
        </TwoColumns>
      </>
    );
  }
}

export default Projects;
