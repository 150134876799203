import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import Farsi from '../lang/fa.json';
import Swedish from '../lang/sv.json';
import English from '../lang/en.json';

export const Context = React.createContext();

const local =navigator.language;//'fa';// 

let lang;
if (local === 'en') {
    lang = English;
} else {
    if (local === 'fa') {
        lang = Farsi;
    } else {
        lang = Swedish;
    }
}

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);

    const [messages, setMessages] = useState(lang);

    function selectLanguage(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else {
            if (newLocale === 'fa') {
                setMessages(Farsi);
            } else {
                setMessages(Swedish);
            }
        }
    }

    return (
        <Context.Provider value={{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                <div style={locale == "fa" ? {textAlign: "right", direction: "rtl"} : {}}
                     className={locale == "fa" ? "farsi" : ""}>
                    {props.children}
                </div>
            </IntlProvider>
        </Context.Provider>

    );
}


export default Wrapper;